<template>
  <header>
    <div class="left">
      <img v-show="isMobile" class="logo" src="@/assets/images-1/dark_logo_icon.png" alt="" />
      <img src="@/assets/images-1/menu_collapse.png" @click="showMenu" v-show="onOpen && !isCollapse" alt="close menu"
        data-testid="closeMenu" />
      <img src="@/assets/images-1/menu_expand.png" @click="showMenu" v-show="isCollapse || matches" alt="open menu"
        data-testid="openMenu" />
      <div class="page_title" v-show="getPageTitle && !isMobile">{{ getPageTitle }}</div>
    </div>
    <div class="right">
      <PortalSwitch @click="handleClient"></PortalSwitch>
      <el-divider direction="vertical"></el-divider>
      <!-- 通知 -->
      <HeaderNotification></HeaderNotification>
      <Language></Language>
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="login-inner">
          <img src="@/assets/images-1/avatar.png" alt class="member-logo" />
        </div>
        <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
          <div class="name_wrapper">
            <img src="@/assets/images-1/avatar.png" alt class="member-logo" />
            <span class="name" v-html="userName"></span>
          </div>
          <el-dropdown-item command="myProfile" class="myProfile">
            <div class="icon"></div>
            <span>{{ $t('menu.ibProfile') }}</span>
          </el-dropdown-item>
          <el-dropdown-item command="logout" class="logout">
            <div class="icon"></div>
            <span>{{ $t('header.logout') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';
import PortalSwitch from './PortalSwitch.vue';
import isMobile from '@/mixins/isMobile';
import Language from './Language.vue'

export default {
  name: 'vHeader',
  mixins: [isMobile],
  components: { HeaderNotification, PortalSwitch, Language },
  props: { matches: Boolean, onOpen: Boolean, isCollapse: Boolean },
  data() {
    return {
    };
  },
  computed: {
    userName() {
      return this.$store.state.common.userName;
    },
    getPageTitle() {
      const key = `menu.${this.$route.name}`
      return this.$te(key) ? this.$t(`menu.${this.$route.name}`) : false
    },
  },
  methods: {
    showMenu() {
      this.$emit('openMenu');
    },
    handleCommand(command) {
      if (command == 'backToClientPortal' || command == 'logout') helper.signOut(command);
      else if (command == 'myProfile') this.$router.push('/settings');
    },
    handleClient() {
      helper.signOut('backToClientPortal');
    },
  },
  mounted() {
    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      this.$nextTick(() => {
        if (val != this.userName) location.reload();
      });
    };

    this.$ls.on('setUserName', callback); //watch change foo key and triggered callback
  }
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 99;
  top: 0;
  height: 64px;
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0 24px;

  .left {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    .logo {
      width: 32px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
    }

    .page_title {
      font-weight: 600;
      font-size: 16px;
      @include rtl-sass-prop(margin-left, margin-right, 16px);
    }
  }

  .right {
    display: flex;
    align-items: center;
    height: 100%;

    .login-inner {
      width: 32px;
      height: 32px;
      cursor: pointer;
      @include rtl-sass-prop(margin-left, margin-right, 8px);
    }

    .language {
      display: flex;

      .lang-select {
        font-size: 20px;
        margin: 0 8px;
        cursor: pointer;
      }

      .text {
        color: $white;
      }
    }
  }
}
</style>

<style lang="scss">
.login_dropdown_box {
  padding: 8px;

  .el-dropdown-menu__item {
    line-height: 40px;
    font-size: 14px;
    border-radius: 4px;
  }

  .name_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: rgba($warning, 0.2);
    border-radius: 4px;
    padding: 12px;

    .member-logo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: $text-secondary;
    }
  }
}
</style>
