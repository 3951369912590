<template>
  <div :class="['portal_switch', isMobile ? 'is_mobile' : '']" @click="onClick">
    <div class="client"><span>Client</span></div>
    <div class="ib"><span>IB</span></div>
  </div>
</template>

<script>
import isMobile from '@/mixins/isMobile';

export default {
  name: 'PortalSwitch',
  mixins: [isMobile],
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.portal_switch {
  cursor: pointer;
  padding: 2px;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $border-color-base;
  display: flex;
  margin: 0 8px;

  .client,
  .ib {
    width: 44px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
  }

  .ib {
    background-color: $primary;
    color: #fff;
  }
}
</style>
