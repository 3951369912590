<template>
  <el-dropdown trigger="click" @command="langCommand" data-testid="dropdownFlag">
    <div class="language">
      <svg-icon className="lang-select" iconClass="language"></svg-icon>
    </div>
    <el-dropdown-menu slot="dropdown" class="lang-dropdown-box">
      <el-dropdown-item
        v-for="item in languages"
        :key="item.value"
        :command="item"
        :class="{ active: lang == item.value }"
        :data-testid="item.value"
      >
        <img :src="item.icon" alt="" />
        <span>{{ item.label }}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'Language',
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/images/header/en.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/images/header/fr.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/images/header/es.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/images/header/pt.png')
        },
        {
          value: 'ko',
          label: '한국어',
          icon: require('@/assets/images/header/ko.png')
        },
        {
          value: 'zh_CN',
          label: '中文',
          icon: require('@/assets/images/header/cn.png')
        },

        {
          value: 'ja',
          label: '日本語',
          icon: require('@/assets/images/header/ja.png')
        },
        {
          value: 'th',
          label: 'ภาษาไทย',
          icon: require('@/assets/images/header/th.png')
        },
        {
          value: 'vi',
          label: 'Tiếng Việt',
          icon: require('@/assets/images/header/vi.png')
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/images/header/de.png')
        },
        {
          value: 'id',
          label: 'Indonesian',
          icon: require('@/assets/images/header/id.png')
        },
        {
          value: 'ar',
          label: 'عربي',
          icon: require('@/assets/images/header/ar.png')
        }
      ]
    };
  },
  watch: {
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        // 如果找不到預設第一筆
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
        this.$nextTick(function() {
          zE('webWidget', 'setLocale', this.switchLang(val));
        });
        this.$i18n.locale = val;
      }
    }
  },
  computed: {
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    }
  },
  methods: {
    langCommand(command) {
      this.language = command;
    },
    switchLang(lang) {
      switch (lang) {
        case 'en_US':
          return 'en';
        case 'zh_CN':
          return 'zh-CN';
        case 'fr_FR':
          return 'fr';
        case 'ja':
          return 'ja';
        case 'ko':
          return 'ko';
        case 'vi':
          return 'vi';
        case 'th':
          return 'th';
        case 'es':
          return 'es';
        case 'pt':
          return 'pt';
        case 'de':
          return 'de';
        case 'id':
          return 'id';
        case 'ar':
          return 'ar';
        default:
          return 'en';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.language {
  display: flex;
  .lang-select {
    font-size: 20px;
    margin: 0 8px;
    cursor: pointer;
  }

  .text {
    color: $white;
  }
}

.lang-dropdown-box {
  padding: 0;
  border: 0;
  margin-top: 15px;
  background-color: transparent;

  .el-dropdown-menu__item {
    padding: 6px 8px !important;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:nth-last-child(2) {
      border-radius: 0 0 6px 6px;
    }

    img {
      width: 34px;
      @include rtl-sass-prop(margin-right, margin-left, 16px);
    }
  }

  /* 小箭头 */
  /deep/ {
    .popper__arrow {
      // @include rtl-sass-prop(left, right, 54px !important);
      @include rtl-sass-prop-dual(left, 108px !important, right, 54px !important);
    }

    .popper__arrow,
    .popper__arrow::after {
      border-bottom-color: #1c214f !important;
      @include rtl-sass-prop-sd(left, 0px !important);
    }
  }

  .el-dropdown-menu__item {
    line-height: normal;
    background-color: #1c214f;
    position: relative;

    span {
      font-size: 14px;
      padding-top: 3px;
      color: $white;
      display: inline-block;
      vertical-align: baseline;
    }

    &:not(.is-disabled):hover {
      background-color: $secondary;
    }

    &.active,
    &:focus {
      background-color: #04071c;
    }

    &.active,
    &:focus {
      span {
        color: $primary;
      }
    }
  }
}
</style>
