<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside :width="!matches ? center : ''">
      <slide-bar
        menu-click-outside="click"
        :isCollapse.sync="isCollapse"
        :onOpen.sync="onOpen"
        :matches="matches"
        @matchesClose="matchesClose"
      ></slide-bar>
    </el-aside>
    <el-container class="is-vertical">
      <Header :matches="matches" :onOpen.sync="onOpen" :isCollapse.sync="isCollapse" @openMenu="openMenu"></Header>
      <div>
        <router-view></router-view>
        <SessionDialog />
      </div>
    </el-container>
  </el-container>
</template>

<script>
import Header from '@/components/Header/index';
import slideBar from '@/components/Menu';
import SessionDialog from '@/components/SessionDialog.vue';
import { apiSetlanguage } from '@/resource';

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0,
      center: '240px',
      matches: false,
      smallMatches: false,
      isCollapse: false,
      onOpen: true
    };
  },
  components: { Header, slideBar, SessionDialog },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) this.setUserLanguagePreference(val);
        this.reRender++;
      }
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) this.center = '240px';
      else if (!this.matches) this.center = '64px';
      else this.center = '0';
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '240px';
      }
    },
    smallMatches(bool) {
      if (bool) {
        this.onOpen = false;
      }
    }
  },
  mounted() {
    const vm = this;
    vm.checkSize();
    $(window).resize(function() {
      vm.checkSize();
    });
  },
  methods: {
    checkSize() {
      let w = window.innerWidth;

      if (w <= 1023 && w >= 768) {
        this.smallMatches = window.matchMedia('(max-width: 1023px)').matches;
        this.matches = false;
      } else if (w < 768) {
        this.matches = window.matchMedia('(max-width: 768px)').matches;
        this.smallMatches = false;
      } else {
        this.matches = false;
        this.smallMatches = false;
      }
    },
    setUserLanguagePreference(lang) {
      apiSetlanguage({ language: lang });
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    },
    matchesClose() {
      this.onOpen = !this.onOpen;
      this.isCollapse = false;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
</style>
