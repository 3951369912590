<template>
    <!-- <vDialog :show.sync="visible">
      <el-row class="dialog-info" type="flex" align="middle">
        <i class="el-icon-warning red"></i>
        <div class="dialog-content">
          <div class="title">{{ $t('sessionDialog.title') }}</div>
          <div>{{ $t('sessionDialog.content') }}</div>
        </div>
      </el-row>
  
      <el-row class="dialog-btns" type="flex" justify="end">
        <el-button class="btn-blue" size="small" @click="keepIn" data-testid="keepIn">{{
          $t('sessionDialog.keepIn')
        }}</el-button>
        <el-button class="btn-default" size="small" plain @click="signOut" data-testid="signOut">{{
          $t('sessionDialog.signOut')
        }}</el-button>
      </el-row>
    </vDialog> -->
    <s-dialog :visible.sync="visible" :title="$t('sessionDialog.title')" cancelButtonTestId="keepIn"
      confirmButtonTestId="signOut" :cancelButtonText="$t('sessionDialog.keepIn')"
      :confirmButtonText="$t('sessionDialog.signOut')" @cancel="keepIn" @confirm="signOut">
      <StatusMessage type="success" class="status_session">
        <template #content>
          <p class="session_content">{{ $t('sessionDialog.content') }}</p>
        </template>
      </StatusMessage>
    </s-dialog>
</template>

<script>
// import vDialog from '@/components/vDialog.vue';
import { setCookies, getCookies, getExpiresTime } from '@/util/cookies';
import { minusTime } from '@/util/time';
import helper from '@/util/signinHelper';

export default {
  components: { },
  data() {
    return {
      visible: false,
      alertSec: 60 * 1000,
      countDown: null,
      totalTime: null,
      signOutTimer: null
    };
  },
  mounted() {
    this.inital();
  },
  methods: {
    inital() {
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
    },
    timeoutFunc() {
      this.countDown = 60;
      this.signOutTimer = this.setInter(1000, this.intervalFunc);
      this.visible = true;
    },
    intervalFunc() {
      this.countDown >= 0 ? this.countDown-- : this.signOut();
    },
    setTime(time, func) {
      return setTimeout(() => {
        func();
      }, time);
    },
    setInter(time, func) {
      return setInterval(() => {
        func();
      }, time);
    },
    getTotalTime() {
      return minusTime(new Date(), getExpiresTime('token'));
    },
    keepIn() {
      if (!getCookies('token')) return this.signOut();

      const token = getCookies('token');
      setCookies('token', token, 30);
      this.setTime(this.getTotalTime() - this.alertSec, this.timeoutFunc);
      clearInterval(this.signOutTimer);
      this.visible = false;
    },
    signOut() {
      this.visible = false;
      clearInterval(this.signOutTimer);
      helper.signOut('logout');
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/assets/css/components/sessionDialog.scss';
.status_session {
  display: flex;
}
</style>
